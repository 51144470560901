<template>
  <div>
    <i-table stripe :data="fileList" :columns="columns"></i-table>
    <Modal title="查看大图" v-model="showPreview" width="800">
        <img :src="formatImgUrl(fileUrl, 'fileModalImg')" id="fileModalImg" v-if="showPreview" style="width: 100%">
      </Modal>
  </div>
</template>

<script>
import { downloadFileRequest, downloadImgRequest } from '@/utils/download'

export default {
  props: {
    fileList: {
      type: Array,
      required: true
    },
    downloadUrl: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      columns: [
        {
          title: '文件缩略图',
          render: (h, data) => {
            if (data.row.mimeType.startsWith('image')) {
              return h('Icon', {
                props: { type: 'md-images', size: 38 },
                style: { 'margin-left': '8px' }
              })
            } else if (this.isMSWord(data.row)) {
              return h('img', { attrs: { src: require('../../assets/images/WinWordLogoSmall.scale-180.png'), height: '50px' } })
            } else if (this.isMSExcel(data.row)) {
              return h('img', { attrs: { src: require('../../assets/images/ExcelLogoSmall.scale-180.png'), height: '50px' } })
            } else {
              return h('Icon', {
                props: { type: 'md-copy', size: 38, color: '#19be6b' },
                style: { 'margin-left': '8px' }
              })
            }
          }
        },
        { title: '文件名称', key: 'fileName' },
        { title: '文件大小 (kb)', key: 'length' },
        { title: '上传时间', key: 'updateTime' },
        {
          title: '操作',
          render: (h, data) => {
            let preview
            if (data.row.mimeType.startsWith('image')) {
              preview = h('a',
                {
                  style: {
                    'margin-left': '5px'
                  },
                  on: {
                    click: () => {
                      this.fileUrl = data.row.url
                      this.showPreview = true
                    }
                  }
                }, '预览')
            }
            return h('div', [
              h('a', {
                on: {
                  click: () => {
                    this.handleDownload(data.row)
                  }
                }
              }, '下载'), preview
            ])
          }
        }
      ],
      showPreview: false,
      fileUrl: ''
    }
  },
  methods: {
    formatImgUrl (url, imgElementId) {
      return downloadImgRequest(url, imgElementId)
    },
    handleDownload (file) {
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + this.downloadUrl, { fileId: file.id }, file.fileName)
    },
    isMSWord (file) {
      const mimeTypes = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword']
      return mimeTypes.includes(file.mimeType)
    },
    isMSExcel (file) {
      const mimeTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
      return mimeTypes.includes(file.mimeType)
    }
  },
  created () {
  }

}
</script>
