<template>
  <div>
    <div class="workplatform-title">基础信息</div>
    <Row>
      <i-col span="8">
        <span class="title">名称 </span><span>{{ contract.contractName }}</span>
      </i-col>
      <i-col span="8">
        <span class="title">客户名称 </span
        ><span>{{ contract.firstParty.advertiserName }}</span>
      </i-col>
      <i-col span="8">
        <span class="title">备注信息 </span><span>{{ contract.mark }}</span>
      </i-col>
    </Row>

    <div class="workplatform-title m-t-10">甲方</div>
    <Row>
      <i-col span="8">
        <span class="title">甲方名称 </span
        ><span>{{ contract.firstParty.name }}</span>
      </i-col>
      <i-col span="8">
        <span class="title">联系人 </span
        ><span>{{ contract.firstParty.contact }}</span>
      </i-col>
      <i-col span="8">
        <span class="title">联系电话 </span
        ><span>{{ contract.firstParty.tel }}</span>
      </i-col>
    </Row>
    <Row class="p-t-5">
      <i-col span="8">
        <span class="title">发票类型 </span>
        <Tag
          :color="contract.firstParty.invoiceType === 1 ? '#44bd32' : '#a59426'"
          type="border"
        >
          {{ contract.firstParty.invoiceTypeName }}</Tag
        >
      </i-col>
      <i-col span="8">
        <span class="title">税号 </span
        ><span>{{ contract.firstParty.code }}</span>
      </i-col>
      <i-col span="8">
        <span class="title">地址 </span
        ><span>{{ contract.firstParty.address }}</span>
      </i-col>
    </Row>

    <div class="p-b-10 m-t-10 workplatform-title">所含订单</div>
    <i-table stripe :data="contract.orders" :columns="orderTitles"></i-table>

    <!-- 费用汇总 -->
    <price-summary
      v-if="contract.priceOrder.length"
      :orders="contract.priceOrder"
    ></price-summary>

    <div class="workplatform-title m-t-10" v-if="contract.payments.length">
      付款信息
    </div>
    <ol class="m-l-20" v-if="contract.payments.length">
      <li v-for="(payment, i) in contract.payments" :key="i">
        于 {{ formatDate(payment.date) }} 之前， 支付乙方：
        <span style="color: green; font-weight: bold">
          {{ formatMoney(payment.money) }}</span
        >
        <span v-if="payment.mark && payment.mark.length > 0"
          >，注：{{ payment.mark }}</span
        >
      </li>
    </ol>

    <div class="workplatform-title p-b-10 m-t-10">附件信息</div>
    <files
      :fileList="attachmentfiles"
      :downloadUrl="'/ooh-scp/v1/contractfile/downloadfile'"
    ></files>

    <Row class="p-t-10">
      <i-col span="24">
        <i-button type="info" @click="handlePreviousStep">上一步</i-button>
        <i-button class="right" type="success" @click="handleNextStep"
          >提交审批</i-button
        >
      </i-col>
    </Row>
    <Modal v-model="showChoiceSubModal">
      <p slot="header">
        <span>提交合同审批</span>
      </p>
      <div class="p-l-10 p-r-10">
        <p class="p-t-10">请选择审批通道：</p>
        <RadioGroup v-model="sub">
          <Radio
            v-for="(item, i) in subs"
            :key="i"
            :label="item.subProcessType"
            >{{ item.subProcessTypeName }}</Radio
          >
        </RadioGroup>
      </div>
      <div slot="footer">
        <i-button
          type="primary"
          @click="handleSubmit"
           :loading="loadingSubmit"
          >确认</i-button
        >
      </div>
    </Modal>
  </div>
</template>

<script>
import priceSummary from '../common/PriceSummary'
import Files from '@/components/common/FilesV2'
import { getSubProcessList } from '@/api/approval/processconfig'

import { ParseDate, GetCurrentSchedule } from '@/utils/dateFormat'
import { toMoney } from '@/utils/wnumb_own'
import { updateDraft, getProcessTypeByVersionId } from '@/api/scp/contract'
import { getFileList } from '@/api/scp/contractfile'

export default {
  props: {
    params: Object,
    isPlatform: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      contract: {},
      attachmentfiles: [],
      orderTitles: [
        { title: '客户', key: 'advertiserName' },
        { title: '品牌', key: 'brandName' },
        {
          title: '投放档期',
          render: (h, data) => {
            return h(
              'span',
              GetCurrentSchedule(data.row.startDate, data.row.endDate)
            )
          }
        },
        { title: '备注', key: 'remark' }
      ],

      showChoiceSubModal: false,
      subs: [],
      sub: null,

      loadingSubmit: false
    }
  },
  methods: {
    handlePreviousStep () {
      this.$emit('prev-step')
    },
    handleNextStep () {
      this.initSubProcessList()
      this.showChoiceSubModal = true
      // this.$Modal.confirm({
      //   title: '操作提示',
      //   content: '确认提交至审批通道？',
      //   onOk: () => {
      //     this.handleSubmit()
      //   }
      // })
    },
    initSubProcessList () {
      getProcessTypeByVersionId({ versionId: this.contract.versionId, processTypeFeature: 0 }).then(res => {
        getSubProcessList({ processType: res }).then((data) => {
          if (data && data.length > 0) {
            this.subs = data
            this.sub = this.subs.length ? this.subs[0].subProcessType : ''
          } else {
            this.subs = []
          }
        })
      })
    },
    getFiles () {
      if (this.contract.versionId) {
        getFileList({
          relateId: this.contract.versionId,
          type: 'CONTRACT_ATTACHMENT_FILE'
        }).then((res) => {
          this.attachmentfiles = res.map((x) => {
            return Object.assign({}, x, {
              url:
                process.env.VUE_APP_API_URL_V2 +
                '/ooh-scp/v1/contractfile/downloadfile?fileId=' +
                x.id
            })
          })
        })
      }
    },
    formatDate (date) {
      return ParseDate(date)
    },
    handleSubmit () {
      this.loadingSubmit = true
      const contract = {
        contractId: this.params.id,
        isSubmitDraft: true,
        subProcessType: this.sub
        // 此参数不再硬编码， 后续都从租户配置来获取
        // needApprove: !this.params.createdByAdmin
      }
      updateDraft(contract).then((res) => {
        if (res && !res.errcode) {
          if (this.isPlatform) {
            this.$Notice.success({ desc: '提交成功！' })
            this.$emit('on-submit')
          }
        }

        this.loadingSubmit = false
      })
    },
    formatMoney (number) {
      return toMoney(number)
    }
  },
  components: {
    priceSummary,
    Files
  },
  created () {
    this.contract = this.params
    this.getFiles()
  }
}
</script>
